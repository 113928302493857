import * as React from "react"

import Layout from "../components/layout"
import ZnanyLekarz from "../components/ZnanyLekarz"

import witoldpracki from "../images/witold_pracki.jpeg"



const IndexPage = () => {
  return (
    <Layout>
        <div className="site-content-contain">
          <div className="site-content-wrap">
              <div className="site-content container">
                  <div className="content-area ">
                      <main className="site-main">
                          <article className="container dsvy-team-single-style-1 post-12023 dsvy-team-member type-dsvy-team-member status-publish has-post-thumbnail hentry">
                              <div className="dsvy-team-single">
                                  <div className="dsvy-team-single-inner">
                                      <div className="row">
                                          <div className="col-md-5 col-lg-4">
                                              <div className="dsvy-team-left-inner">
                                                  <div className="dsvy-featured-wrapper">
                                                      <img width="800" height="800"
                                                          src={witoldpracki}
                                                          className="attachment-full size-full wp-post-image"
                                                          alt="Chirurg Witold Pracki"
                                                          sizes="(max-width: 800px) 100vw, 800px" />
                                                  </div>
                                                  <div className="dsvy-team-summary">
                                                      <h2 className="dsvy-team-title">dr n. med. Witold Pracki</h2>
                                                      <h4 className="dsvy-team-designation">Chirurg, proktolog</h4>
                                                  </div>
                                                  
                                                  <div className="dsvy-team-member-details-apply">
                                                    <a href="tel:618-610-123">Umów wizytę: <i className="dsvy-base-icon-call-1"></i>618-610-123</a>
                                                  </div>
                                                  
                                                  <ZnanyLekarz 
                                                    doctor="witold-pracki" 
                                                    type="big_with_calendar" 
                                                    label="Witold Pracki - ZnanyLekarz.pl"
                                                    url="https://www.znanylekarz.pl/witold-pracki/chirurg-proktolog/poznan"
                                                  />
                                                  

                                              </div>
                                          </div>
                                          <div className="col-md-7 col-lg-8">
                                              <div className="dsvy-short-description">
                                                  <h3>O mnie</h3>
                                                  <p>
                                                    Studia na Wydziale Lekarskim Akademii Medycznej w Poznaniu ukończyłem w 1981r.
                                                    Po ukończeniu stażu podyplomowego rozpocząłem pracę w oddziale chirurgicznym szpitala HCP 
                                                    pod fachowym nadzorem dr Jerzego Redelbacha. Tytuł specjalisty w chirurgii ogólnej uzyskałem w 1991r. 
                                                  </p>

                                                  <p>
                                                    W drugiej połowie lat dziewięćdziesiątych opanowałem nową, jak na ówczesne czasy, technikę laparoskopową, 
                                                    obecnie stosowaną powszechnie w chirurgii pęcherzyka żółciowego, wyrostka robaczkowego czy operacjach 
                                                    przepuklin pachwinowych. 
                                                  </p>
                                                  <p>
                                                    Od początku pracy szkoliłem się dodatkowo w zakresie badań diagnostycznych 
                                                    przewodu pokarmowego (gastoskopia, kolonoskopia, ECPW - obrazowanie dróg żółciowych). 
                                                    Ze względu dużego stopnia zaniedbania oraz niewiedzę dotyczącą chorób odbytu zająłem się również 
                                                    proktologią, której do dzisiaj pozostaję wierny.
                                                  </p>
                                                  
                                                  Leczone choroby:
                                                  <ul className="dsvy-mdi-checklist">
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> choroby przewodu pokarmowego,</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> konsultacje dotyczące wskazań do gastroskopii i kolonoskopii,</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> usuwanie zmian skórnych,</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> badanie proktologiczne,</li>
                                                    <li><i aria-hidden="true" className="mdi mdi-check"></i> rektoskopia,</li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </article>
                      </main>
                  </div>
              </div>
          </div>

      </div>

    </Layout>          
  )
}

export default IndexPage
